import React from "react";
import * as styles from "./UltimateSection.module.scss";
import image1 from "../../../images/row1.png";
import image2 from "../../../images/row2.png";
import image3 from "../../../images/row3.png";
import image4 from "../../../images/row4.png";
import { Link } from "gatsby";

function UltimateSection() {
  const data = [
    {
      title: "Products",
      url: "/products",
      image: image1,
      description:
        `In case you did not decide on what to go for, check out our products. Also remember your 2 weeks free trial.
         `,
    },
    {
      title: "3D Spaces",
      image: image2,
      url: "/3d-spaces",
      description:
      `Get a feel for 3D Spaces, the elegant looks and how they are used. We are offering 3D Spaces with all options.
       `,
    },
    {
      title: "Presentations",
      image: image3,
      url: "/articles",
      description:
      `Excellence in presentations starts with know-how and here you can study what we say about communications.
       `,
    },
    {
      title: "Pricing",
      image: image4,
      url: "pricing",
      description:
      `Master Programs are complementing our professional licenses. Look at your options.
       `,
    },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>Unleash your Curiosity</h1>
        <p>Dive into the details with Master Programs, some of the components and examples</p>
      </div>
      <div className={styles.cards}>
        {data.map((item, i) => {
          return (
            <Link to={item.url}>
              <div className={styles.item}>
                <div
                  style={{ backgroundImage: `url(${item?.image})` }}
                  key={i}
                  className={styles.card}
                >
                  <h2>{item.title}</h2>
                  <p>Explore Now</p>
                </div>
                <div className={styles.desc}>
                  <p dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
export default UltimateSection;
