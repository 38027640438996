// extracted by mini-css-extract-plugin
export var aboutSection = "explore-module--aboutSection--d8643";
export var boldText = "explore-module--boldText--f4a5f";
export var boldText2 = "explore-module--boldText2--4c58a";
export var container = "explore-module--container--8cfe6";
export var contentHolder = "explore-module--contentHolder--efece";
export var description = "explore-module--description--5f270";
export var homeSection = "explore-module--homeSection--19a11";
export var link = "explore-module--link--4cfa8";
export var logo = "explore-module--logo--8e803";
export var logos = "explore-module--logos--480d9";
export var logosdiv = "explore-module--logosdiv--99111";
export var mobileContainer = "explore-module--mobileContainer--89e3b";
export var mwimage = "explore-module--mwimage--b4e7f";
export var subtitle = "explore-module--subtitle--2aff5";