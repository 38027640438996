import React from "react";
import * as styles from "./styles.module.scss";
import Card from "../../../atoms/simpleCard";


export default function index({list,title,description,extraClass,image}) {
  return (
    <div className={`${styles.unique} ${styles[extraClass]}`}>
      <div className={styles.content}>
        <div className={styles.desc}>
          <h2>{title}</h2>
          <p  dangerouslySetInnerHTML={{ __html: description}} className={styles.subtitle}>
           {/* {description} */}
          </p>
        </div>
        {!image && <div className={styles.cards}>
          {list.map((d,i) =>{
            return(
              <Card {...d} key={i} extraClass={extraClass}/>
            )
          })}
        </div>}
        {image &&
        <img src="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1681163307/web%20images/Screenshot_2023-04-10_at_2.46.35_PM_mloaed.png" alt="3D interior design of a premium office space with Master Wizr logo on the wall."/>
        }
      </div>
    </div>
  );
}