// extracted by mini-css-extract-plugin
export var bill = "ServicesDetails-module--bill--13f1c";
export var bottom = "ServicesDetails-module--bottom--52306";
export var bottomParagraph = "ServicesDetails-module--bottomParagraph--c6785";
export var card = "ServicesDetails-module--card--0f513";
export var cards = "ServicesDetails-module--cards--813b4";
export var container = "ServicesDetails-module--container--7dbe3";
export var hoverContent = "ServicesDetails-module--hoverContent--f2e45";
export var shade = "ServicesDetails-module--shade--ec982";
export var subtitle = "ServicesDetails-module--subtitle--4cde8";
export var toggle = "ServicesDetails-module--toggle--97164";
export var toggleon = "ServicesDetails-module--toggleon--47712";
export var top = "ServicesDetails-module--top--6fe04";