import icon1 from '../images/user-icon.png';
import icon2 from '../images/tech-icon.png';
import icon3 from '../images/security-icon.png';

import image1 from "../images/row1.png";
import image2 from "../images/row2.png";
import image3 from "../images/row3.png";
import image4 from "../images/row4.png";

export const connectlist1 =[
    {
      title:"Productivity ",
      desc:"Our notes will make it easier to collaborate in ways chat is not doing. Meeting timer, agenda and polls are completing WiZR Connect as a very productivity oriented video conference system.",
      icon:icon1,
      color:"#EC7600"
    },
   {
    title:"Digital fatigue",
    desc:"Being in endless meetings on a day-to-day basis can be tiring and monotonous. WiZR Connect aims to offer rich interactions, personalized to your needs and goals.",
    icon:icon2,
    color:"#2E6055",
   },
   {
    title:"Creative platform ",
    desc:"Who said that video conference is a one-color option? Dark or bright. Hundreds of high end virtual backgrounds are available in hundreds of color modes and backgrounds. Expand your virtual presence and identity in new ways.",
    icon:icon3,
    color:"#0693DA",
   }
  ]
  export const connectlist2 =[
    {
      title:"Diversity a Superpower ",
      desc:"Leveraging diversity in our 4-continent team, we cultivate fresh perspectives, new ideas, and innovative digital collaboration and learning.",
      icon:icon1,
      color:"#EC7600",
      image:image1
    },
   {
    title:"From 1% to the 99%",
    desc:"Inspired by the top 1% of businesses globally, we're on a mission to uplift the other 99% of the business community, enabling them to shine professionally.",
    icon:icon2,
    color:"#2E6055",
    image:image2
   },
   {
    title:"Partner-driven ",
    desc:"Our univeral communication tool is designed to meet the needs of businesses that aspire to greatness. We strive to empower growth, learning, and opportunity for all.",
    icon:icon3,
    color:"#0693DA",
    image:image3
   }
  ]

  export const connectlist3 =[
    {
     title:"Early Adopters",
     desc:"They are ahead of the curve, using cutting-edge tools that reimagine how material is organized and partnerships are formed, taking their work to the next level. ",
     image:image1
    },
    {
      title:"Best in Class ",
      desc:"These groups are excellence seekers, always striving to increase productivity and efficiency to stay ahead. They look to present themselves at the highest level to stand out from the competition.",
      image:image2
    },
   {
    title:"Multi Audience Communicators",
    desc:"Institutions and organizations that require personalized content for both internal and external groups. Easily customize and share material across a wide range of audiences. ",
    image:image3
   },
   {
    title:"Design Champions ",
    desc:"Our platform is built upon the principles of design elegance, catering to the needs of design champions and tastemakers who strive to optimize their material and showing their unique edge.",
    image:image4
  },
  ]
