import React from 'react'
import * as styles from './styles.module.scss';

export default function index({icon, title, desc,color,extraClass,image}) {
    return (
        <div className={`${styles.card} ${styles[extraClass]}`}>
            <div className={styles.icon} style={{ backgroundColor: `${color?color:""}` }}>
              <img src={icon}  width={35} height={33}  alt="icon"/>
            </div>
            <div style={{backgroundImage:`url(${image})`}} className={styles.divider}></div>
            <h2>{title}</h2>
            <p>
                {desc}
            </p>
        </div>
    )
}