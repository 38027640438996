import * as styles from "./UltimateBanner.module.scss";
import React from "react";
function UltimateBanner() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
      </div>
<div className={styles.image}>
<img src={"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1681151761/web%20images/StudioNew_2_1_1_puab8q.png"} alt="3D design product demo of Master Wizr Studio showcasing a showroom cover page."/>

</div>
    </div>
  );
}
export default UltimateBanner;
