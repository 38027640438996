import React, { useState } from "react";
import * as styles from "./ServicesDetails.module.scss";
import image1 from "../../../images/paymentcard1.png";
import image2 from "../../../images/paymentcard2.png";
import image3 from "../../../images/paymentcard3.png";
import image4 from "../../../images/paymentcard4.png";
function ServicesDetails(){
    const [state,setState] = useState(false);
    const [hoverState,setHoverState]=useState();
    const data = [
        {
            license:"Light",
            price:!state ? `250 USD` : `120 USD`,
            subtitle:"Light",
            description:"First 2 weeks focus on quick wins, for small teams.",
            bgGradient:"linear-gradient(rgba(229, 108, 20, 0.72), rgb(190, 83, 42)) 0px 0px no-repeat padding-box padding-box transparent",
            image:image1,
            hoverContent:`
            <ul> 
            <li>Access to +100 users cases</li>
            <li>Follow-ups after 3+10 days</li>
            <li>1 Show Room visual</li>
            <li>Presentation templates</li>
            </ul>
            `
        },
        {
            license:"Medium",
            price:!state ? `750 USD` : `240 USD`,
            subtitle:"Medium",
            description:"First 2 weeks focus on quick wins, for 5 licenses and more.",
            bgGradient:"linear-gradient(rgb(6 147 218 / 77%), rgb(6, 98, 144)) 0px 0px no-repeat padding-box padding-box transparent",
            image:image2,
            hoverContent:`
            <ul> 
            <li>Access to +100 users cases</li>
            <li>Follow-ups after 3+10 days</li>
            <li>1 Show Room logo customized</li>
            <li><b>10 pages presentation</b></li>
            </ul>
            `
        },
        {
            license:"Regular",
            price:!state ? `1250 USD` : `360 USD`,
            subtitle:"Regular",
            description:"First 4 weeks focus on smart preparations for several departments.",
            bgGradient:"linear-gradient(rgb(76 181 158 / 68%), rgb(8, 72, 57)) 0px 0px no-repeat padding-box padding-box transparent",
            image:image3,
            hoverContent:`
            <ul> 
            <li>Access to +100 users cases</li>
            <li>Follow-ups after 3+10 days</li>
            <li>1 Show Room logo customized</li>
            <li>10 pages presentation</li>
            <li><b>2 Show Rooms </b></li>
            </ul>
            `
        },
        {
            license:"Pro",
            price:!state ? `2500 USD` : `120 USD each`,
            description:"Covers a full year year of quarterly review and support.",
            subtitle:"Pro",
            btn:"More Info",
            bgGradient:"linear-gradient(rgb(70 93 134 / 84%), rgb(55, 75, 110)) 0px 0px no-repeat padding-box padding-box transparent",
            image:image4,
            hoverContent:`
            <ul> 
            <li>All of the Regular </li>
            <li><b>Follow-up quarterly </b> </li>
            <li><b>VIP answering</b> </li>
            <li><b>3D Teams customized</b></li>
            <li><b>Beta privileges</b> </li>

            </ul>
            `
        },
    ]
    return(
        <div className={styles.container}>
         <h1>MASTER Programs - More than onboarding</h1>
         <p className={styles.subtitle}>Our practical options provide comprehensive solutions that cover the initial weeks, first month and year.</p>
         <div className={styles.cards}>
             {data?.map((item,i) =>{
                 return(
                     <div key={i} onMouseOver={()=>setHoverState(i)} onMouseOut={()=>setHoverState("")} style={{backgroundImage:`url(${item.image})`}}  className={styles.card}>
                         <div style={{background:item.bgGradient}} className={styles.shade}></div>
                         <div className={styles.top}>
                             <h3>{item.license}</h3>
                             <h2>{item.price}</h2>
                             </div>
                             {hoverState===i ?
                             <div className={styles.hoverContent}>
   <p dangerouslySetInnerHTML={{ __html: item.hoverContent }}></p>
   <button>{item?.btn ? item.btn : `More Info`}</button>
                             </div> 
                             :
                             <div className={styles.bottom}>
                                 <h4>{item.subtitle}</h4>
                                 <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                 <button>{item?.btn ? item.btn : `More Info`}</button>
                                 </div>
                             }
                         </div>
                 )
             })}
         </div>
         <p className={styles.bottomParagraph}>PS! Hover the info cards  </p>
        </div>
    );
}
export default ServicesDetails;